import React from "react";
import SvgPaths from "../../UsMapInline";

const Section3 = () => {
  return (
    <>
      <div className="container -fw">
        <div className="text-wrapper-section">
          <h4>3 cities, 9 facilities</h4>
          <h6>
            We’re live in New York, San Francisco Bay Area, and Los Angeles.
          </h6>
          <p className="map-icon">
            <span>
              Our most popular location is RadNet Park Avenue, located in New
              York City.
            </span>
          </p>
        </div>
        <div className="interactive-svg-map">
          <SvgPaths />
        </div>
      </div>
    </>
  );
};

export default Section3;
