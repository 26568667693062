import React from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
const EzBarChart = ({ isMobile, data }) => {
  return (
    <ResponsiveContainer
      width={isMobile ? 380 : `100%`}
      height={isMobile ? 280 : 480}
    >
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" vertical={0} strokeOpacity={0.2} />
        <XAxis dataKey="name" />
        <YAxis tick={false} />
        <Bar dataKey="scans" fill="#FFC000" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EzBarChart;
