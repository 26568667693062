import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
import Spacer from "../spacer/spacer";
import Asia from "../../assets/images/testimonials/Asia-1300x810.jpg";
import Barbara from "../../assets/images/testimonials/Barbara-1300x881.jpeg";
import Brian from "../../assets/images/testimonials/Brian-65-1300x881.jpg";
import Russell from "../../assets/images/testimonials/Russell-55-1300x881.jpg";
import Patricia from "../../assets/images/testimonials/Patricia-75-1300x881.jpg";
import John from "../../assets/images/testimonials/John-48-1300x881.jpg";
import Patrick from "../../assets/images/testimonials/Patrick-36-1300x881.jpg";
import Daniel from "../../assets/images/testimonials/Daniel-57-1300x881.jpg";
import arrowLeft from "../../assets/svg/1000_testimonial_arrow_left.svg";
import arrowRight from "../../assets/svg/1000_testimonial_arrow_right.svg";

const TestimonialSlider = ({ playSlider }) => {
  const [sliderClasses, setSliderClasses] = useState("");
  const sliderRef = useRef(null);
  const staticImgRefs = useRef([]);
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    touchThreshold: 15,
    fade: true,
    speed: 0,
    cssEase: "linear",
    swipe: false,
    autoplay: true,
    autoplaySpeed: 11000,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 150,
          adaptiveHeight: true,
          swipe: true,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: "Daniel",
      about: "57, New York",
      copy: `"So, Ezra saved my life. That's the simple statement. I had surgery Sep 25th and today, Oct 1st, I got the pathology back, and am now cancer-free. I'll go into a regimen of surveillance against recurrence instead of chemotherapy or further treatment. This particular kind of cancer is usually not discovered until late, and so even though it's particularly operable, it's also particularly deadly. But here I am, because my Ezra scan served as my early warning system against problems." — Stock photo was used for member privacy`,
      img: Daniel,
    },
    {
      name: "Patrick",
      about: "36, New York",
      copy: `"In February, I scheduled a full-body scan with Ezra after a close friend was diagnosed with cancer. I had no reason for concern, it was just a screening, so I was very surprised to find that my scan turned up an alarmingly large brain tumor. I've since undergone surgery, which fortunately went well, and the pathology results were favorable. My doctors told me that had I not gotten that scan, it could have easily been 5 to 10 years before the tumor had been discovered at the onset of symptoms. In all likelihood, my scan bought me years of additional quality of life, and for that my family and I will always be profoundly grateful." — Stock photo was used for member privacy`,
      img: Patrick,
    },
    {
      name: "John",
      about: "48, New York",
      copy: `"You guys saved my life. I ended up having double bypass surgery after your calcium score allowed me to force my cardiologist to order an angiogram. It showed a 90% and 80% blockage [of heart arteries]. I had Coronary Artery Bypass Surgery right before Covid-19 hit us." — Stock photo was used for member privacy`,
      img: John,
    },
    {
      name: "Patricia",
      about: "75, New Jersey",
      copy: `"My Ezra scan has given me peace of mind. After learning about my findings, I am now aware of things I need to monitor that I was not aware of prior to my scan. The Ezra experience was pleasant, from beginning to end. Everyone was professional and knowledgeable, including the staff at the facility. Signing up for an Ezra full body scan was the best thing I ever did for myself." — Stock photo was used for member privacy`,
      img: Patricia,
    },
    {
      name: "Russell",
      about: "55, New York",
      copy: `"My experience with Ezra was very positive. The whole process was seamless and the representatives were really friendly, patient, and knowledgeable about the whole thing. I found some information that proved to be almost lifesaving so it was definitely worth doing. I definitely plan to go back." — Stock photo was used for member privacy`,
      img: Russell,
    },
    {
      name: "Brian",
      about: "65, New Jersey",
      copy: `“MRIs are a powerful tool in early cancer detection. I hope someday soon everyone will have access to this life-saving diagnostic tool as part of an annual exam. My experience, from the initial call to the final report, was professional, very informative and the most comprehensive exam I have ever done. The details provided in the Ezra scan can give you peace of mind,  and/or identify problems before they manifest themselves - at an early, treatable, stage.” — Stock photo was used for member privacy`,
      img: Brian,
    },
    {
      name: "Barbara",
      about: "61, Texas",
      copy: `“My Ezra scan was 100% worth it. Health is very important in life and unless you have symptoms, a doctor will not generally send you for the type of scan that Ezra provides. With a family history of cancer, I learned that my father had no symptoms prior to his cancer diagnosis - hence the importance of early detection. Ezra handled every step with care, and the discussion of my findings was professional. My scan picked up an issue that I was able to follow up with my doctor on, that I need to continue monitoring. Without Ezra I would not have this peace of mind.” — Stock photo was used for member privacy`,
      img: Barbara,
    },
    {
      name: "Asia",
      about: "43, New Jersey",
      copy: `"The Ezra organization was very helpful, the staff was very kind & for that I am grateful. Additionally, the facility was very virtuous. Everyone there made me feel very comfortable & I will be referring Ezra to all my friends & family!" — Stock photo was used for member privacy`,
      img: Asia,
    },
  ];

  const handleBeforeChange = (currentSlide, nextSlide) => {
    var decl = currentSlide - nextSlide;
    if (decl === 1 || decl === (testimonials.length - 1) * -1) {
      setSliderClasses("is-prev");
      staticImgRefs.current[nextSlide].style.width = "100%";
      staticImgRefs.current[currentSlide].style.right = "0";
      staticImgRefs.current[currentSlide].style.left = "auto";
      staticImgRefs.current[currentSlide].style.width = "0%";
    } else {
      setSliderClasses("is-prev is-next");
      staticImgRefs.current[currentSlide].style.width = "0%";
      staticImgRefs.current[nextSlide].style.right = "0";
      staticImgRefs.current[nextSlide].style.left = "auto";
      staticImgRefs.current[nextSlide].style.width = "100%";
    }
  };

  const handleAfterChange = () => {
    staticImgRefs.current.map((elem, index) => {
      elem.style.removeProperty("right");
      elem.style.removeProperty("left");
    });
    setSliderClasses("");
    // setTimeout(() => {
    //   $(`.static-images img`).each(function (index, elem) {
    //     elem.style.removeProperty("right");
    //     elem.style.removeProperty("left");
    //     $testimonialsSlider.removeClass("is-prev").removeClass("is-next");
    //   });
    // }, 1500);
  };

  const handleSlickNext = () => {
    staticImgRefs.current.map((img, index) => {
      if (img.style.width === "100%" && index === testimonials.length - 1) {
        sliderRef.current.slickGoTo(0);
      } else {
        sliderRef.current.slickNext();
      }
    });
  };

  const handleSlickPrev = () => {
    staticImgRefs.current.map((img, index) => {
      if (img.style.width === "100%" && index === 0) {
        sliderRef.current.slickGoTo(testimonials.length - 1);
      } else {
        sliderRef.current.slickPrev();
      }
    });
  };

  useEffect(() => {
    if (playSlider) {
      sliderRef.current.slickPlay();
    } else {
      sliderRef.current.slickPause();
    }
  }, [playSlider]);
  return (
    <>
      <section className="testimonials-slider">
        <div className="container">
          <Spacer size={64} mobileSize={48} />
          <div className="row">
            <div className="col-16 col-sm-16 col-md-7 offset-md-1">
              <h4 className="heading">
                To know is to thrive. Here are some of our members’ stories.
              </h4>
            </div>
          </div>
          {isMobile && (
            <>
              <div className="row">
                <div className="col-15 d-flex d-md-none">
                  <div className="testimonial-slider-arrows">
                    <img
                      src={arrowLeft}
                      onClick={handleSlickPrev}
                      alt="Left Arrow"
                    />
                    <img
                      src={arrowRight}
                      onClick={handleSlickNext}
                      alt="Right Arrow"
                    />
                  </div>
                </div>
              </div>
              <Spacer size={0} mobileSize={48} />
            </>
          )}

          <div className="row">
            <div className="col-16 col-sm-16 col-md-14 offset-md-1">
              <div className="static-images">
                {testimonials.map((testimonial, index) => {
                  return (
                    <div
                      ref={(element) => {
                        staticImgRefs.current[index] = element;
                      }}
                      key={`ttimg-${index}`}
                      className={`current-${index}`}
                      style={{
                        backgroundImage: `url(${testimonial.img}`,
                      }}
                      alt={`static-img-${index}`}
                    />
                  );
                })}
              </div>
              <Slider
                {...settings}
                ref={sliderRef}
                className={`slider-wrapper-testimonials ${sliderClasses}`}
                beforeChange={handleBeforeChange}
                afterChange={handleAfterChange}
              >
                {testimonials.map((testimonial, index) => {
                  return (
                    <div key={`ttcpy-${index}`} className="member-slider-item">
                      <div className="img-wrapper"></div>
                      <div className="text-wrapper-slide">
                        <div className="text-effect">
                          <p className="center-me">{testimonial.copy}</p>
                          <p className="name">
                            {testimonial.name} <span>—{testimonial.about}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
          <div className="row d-none d-lg-flex">
            <div className="col-15">
              <div className="testimonial-slider-arrows">
                <img
                  src={arrowLeft}
                  onClick={handleSlickPrev}
                  alt="Left Arrow"
                />
                <img
                  src={arrowRight}
                  onClick={handleSlickNext}
                  alt="Right Arrow"
                />
              </div>
            </div>
          </div>
          <Spacer size={63} mobileSize={48} />
        </div>
      </section>
    </>
  );
};

export default TestimonialSlider;
