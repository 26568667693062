import React from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
} from "recharts";

const EzBarChart = ({ isMobile, data }) => {
  return (
    <ResponsiveContainer
      width={isMobile ? 380 : `100%`}
      height={isMobile ? 280 : 480}
    >
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" vertical={0} strokeOpacity={0.2} />
        {isMobile ? (
          <XAxis dataKey="name" interval="preserveStartEnd" />
        ) : (
          <XAxis dataKey="name" />
        )}
        <YAxis tick={false} />
        <Bar dataKey="female" fill="#B8DCE0" opacity="0.5" stackId="a" />
        <Bar dataKey="male" fill="#B8DCE0" stackId="a" />
        <Legend
          verticalAlign="bottom"
          height={36}
          payload={[
            { id: "male", value: "Male", type: "circle", color: "#B8DCE0" },
            {
              id: "female",
              value: "Female",
              type: "circle",
              color: "#61767f",
            },
          ]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EzBarChart;
