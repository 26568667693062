import { useStaticQuery, graphql } from "gatsby";
export const useReportsData = () => {
  const data = useStaticQuery(graphql`
    {
      allReportsCsv {
        nodes {
          Home_city
          Home_state_country
          Amount
          id
          Lat
          Long
        }
      }
    }
  `);
  return data.allReportsCsv.nodes;
};
