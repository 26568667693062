import React, { useState, useRef, useEffect } from "react";
import iconCloseSvg from "../../assets/svg/icon-close-black.svg";
import CheckSvg from "../../assets/svg/dudePlus.inline.svg";
import { isMobile } from "react-device-detect";

const FullBodyDude = () => {
  const nodes = useRef([]);
  const [activeOrgan, setActiveOrgan] = useState(null);
  let activeFlag = false;
  const handleClickOutside = (e) => {
    let flag = false;
    nodes.current.map((node) => {
      if (node.contains(e.target)) {
        flag = true;
      }
      return null;
    });

    if (flag) {
      return;
    }

    setActiveOrgan({
      head: false,
      neck: false,
      chest: false,
      spleen: false,
      prostate: false,
    });
  };

  const handleScroll = (e) => {
    setActiveOrgan({
      head: false,
      neck: false,
      chest: false,
      spleen: false,
      prostate: false,
    });
  };

  if (activeOrgan) {
    for (const [key, value] of Object.entries(activeOrgan)) {
      if (value) {
        activeFlag = value;
      }
    }
  }

  useEffect(() => {
    if (activeFlag) {
      document.addEventListener("click", handleClickOutside);
      if (isMobile) {
        document.addEventListener("scroll", handleScroll);
      }
    } else {
      document.removeEventListener("click", handleClickOutside);
      if (isMobile) {
        document.removeEventListener("scroll", handleScroll);
      }
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
      if (isMobile) {
        document.removeEventListener("scroll", handleScroll);
      }
    };
  }, [activeOrgan]);
  return (
    <>
      <div className="img-wrapper-person"></div>
      <div className="img-wrapper-dude">
        <div className="img-wrapper-icon -icon-head">
          <span
            className={`icon-svg ${activeOrgan?.head ? "active" : ""}`}
            role="button"
            aria-label="Select Head"
            tabIndex={0}
            onKeyDown={() =>
              setActiveOrgan({
                head: activeOrgan?.head ? false : true,
                neck: false,
                chest: false,
                spleen: false,
                prostate: false,
              })
            }
            onClick={() =>
              setActiveOrgan({
                head: activeOrgan?.head ? false : true,
                neck: false,
                chest: false,
                spleen: false,
                prostate: false,
              })
            }
          ></span>
          <div
            className={`organ-popup ${
              activeOrgan?.head
                ? `active ${!isMobile && "scale-in-tr"}`
                : `${!isMobile && "active scale-out-tr"}`
            }`}
            ref={(element) => {
              nodes.current[0] = element;
            }}
          >
            <span
              className="d-block d-md-none mobile-close"
              role="button"
              aria-label="Close"
              tabIndex={0}
              onKeyDown={() =>
                setActiveOrgan({
                  head: false,
                  neck: false,
                  chest: false,
                  spleen: false,
                  prostate: false,
                })
              }
              onClick={() =>
                setActiveOrgan({
                  head: false,
                  neck: false,
                  chest: false,
                  spleen: false,
                  prostate: false,
                })
              }
            >
              <img src={iconCloseSvg} alt="mobile close" />
            </span>
            <h4>Head</h4>
            <div className="organ-popup-list">
              <div className="organ-popup-list-header">
                <span>Organs covered</span>
              </div>
              <ul>
                <li>
                  <span>Brain</span>
                  <CheckSvg />
                </li>
                <li>
                  <span>Spine</span>
                  <CheckSvg />
                </li>
              </ul>
            </div>
            <a
              href="https://ezra.com/full-body/"
              target="_blank"
              className="link-btn -secondary"
            >
              <span>Read more</span>
            </a>
          </div>
        </div>
        <div className="img-wrapper-icon -icon-neck">
          <span
            className={`icon-svg ${activeOrgan?.neck ? "active" : ""}`}
            onClick={() =>
              setActiveOrgan({
                head: false,
                neck: activeOrgan?.neck ? false : true,
                chest: false,
                spleen: false,
                prostate: false,
              })
            }
          ></span>
          <div
            ref={(element) => {
              nodes.current[1] = element;
            }}
            className={`organ-popup ${
              activeOrgan?.neck
                ? `active ${!isMobile && "scale-in-tr"}`
                : `${!isMobile && "active scale-out-tr"}`
            }`}
          >
            <span
              className="d-block d-md-none mobile-close"
              role="button"
              aria-label="Close"
              tabIndex={0}
              onKeyDown={() =>
                setActiveOrgan({
                  head: false,
                  neck: false,
                  chest: false,
                  spleen: false,
                  prostate: false,
                })
              }
              onClick={() =>
                setActiveOrgan({
                  head: false,
                  neck: false,
                  chest: false,
                  spleen: false,
                  prostate: false,
                })
              }
            >
              <img src={iconCloseSvg} alt="mobile close" />
            </span>
            <h4>Neck</h4>
            <div className="organ-popup-list">
              <div className="organ-popup-list-header">
                <span>Organs covered</span>
              </div>
              <ul>
                <li>
                  <span>Thyroid</span>
                  <CheckSvg />
                </li>
              </ul>
            </div>
            <a
              href="https://ezra.com/full-body/"
              target="_blank"
              className="link-btn -secondary"
            >
              <span>Read more</span>
            </a>
          </div>
        </div>
        <div className="img-wrapper-icon -icon-heart">
          <span
            className={`icon-svg ${activeOrgan?.chest ? "active" : ""}`}
            onClick={() =>
              setActiveOrgan({
                head: false,
                neck: false,
                chest: activeOrgan?.chest ? false : true,
                spleen: false,
                prostate: false,
              })
            }
          ></span>
          <div
            ref={(element) => {
              nodes.current[2] = element;
            }}
            className={`organ-popup ${
              activeOrgan?.chest
                ? `active ${!isMobile && "scale-in-tr"}`
                : `${!isMobile && "active scale-out-tr"}`
            }`}
          >
            <span
              className="d-block d-md-none mobile-close"
              role="button"
              aria-label="Close"
              tabIndex={0}
              onKeyDown={() =>
                setActiveOrgan({
                  head: false,
                  neck: false,
                  chest: false,
                  spleen: false,
                  prostate: false,
                })
              }
              onClick={() =>
                setActiveOrgan({
                  head: false,
                  neck: false,
                  chest: false,
                  spleen: false,
                  prostate: false,
                })
              }
            >
              <img src={iconCloseSvg} alt="mobile close" />
            </span>
            <h4>Chest</h4>
            <div className="organ-popup-list">
              <div className="organ-popup-list-header">
                <span>Organs covered</span>
              </div>
              <ul>
                <li>
                  <span>Lung</span>
                  <CheckSvg />
                </li>
              </ul>
            </div>
            <a
              href="https://ezra.com/full-body/"
              target="_blank"
              className="link-btn -secondary"
            >
              <span>Read more</span>
            </a>
          </div>
        </div>
        <div className="img-wrapper-icon -icon-spleen">
          <span
            className={`icon-svg ${activeOrgan?.spleen ? "active" : ""}`}
            onClick={() =>
              setActiveOrgan({
                head: false,
                neck: false,
                chest: false,
                spleen: activeOrgan?.spleen ? false : true,
                prostate: false,
              })
            }
          ></span>
          <div
            ref={(element) => {
              nodes.current[3] = element;
            }}
            className={`organ-popup ${
              activeOrgan?.spleen
                ? `active ${!isMobile && "scale-in-tr"}`
                : `${!isMobile && "active scale-out-tr"}`
            }`}
          >
            <span
              className="d-block d-md-none mobile-close"
              role="button"
              aria-label="Close"
              tabIndex={0}
              onKeyDown={() =>
                setActiveOrgan({
                  head: false,
                  neck: false,
                  chest: false,
                  spleen: false,
                  prostate: false,
                })
              }
              onClick={() =>
                setActiveOrgan({
                  head: false,
                  neck: false,
                  chest: false,
                  spleen: false,
                  prostate: false,
                })
              }
            >
              <img src={iconCloseSvg} alt="mobile close" />
            </span>
            <h4>Abdomen</h4>
            <div className="organ-popup-list">
              <div className="organ-popup-list-header">
                <span>Organs covered</span>
              </div>
              <ul>
                <li>
                  <span>Liver</span>
                  <CheckSvg />
                </li>
                <li>
                  <span>Gallbladder</span>
                  <CheckSvg />
                </li>
                <li>
                  <span>Pancreas</span>
                  <CheckSvg />
                </li>
                <li>
                  <span>Spleen</span>
                  <CheckSvg />
                </li>
                <li>
                  <span>Kidney</span>
                  <CheckSvg />
                </li>
                <li>
                  <span>Adrenal Glands</span>
                  <CheckSvg />
                </li>
              </ul>
            </div>
            <a
              href="https://ezra.com/full-body/"
              target="_blank"
              className="link-btn -secondary"
            >
              <span>Read more</span>
            </a>
          </div>
        </div>
        <div className="img-wrapper-icon -icon-prostate">
          <span
            className={`icon-svg ${activeOrgan?.prostate ? "active" : ""}`}
            onClick={() =>
              setActiveOrgan({
                head: false,
                neck: false,
                spleen: false,
                chest: false,
                prostate: activeOrgan?.prostate ? false : true,
              })
            }
          ></span>
          <div
            ref={(element) => {
              nodes.current[4] = element;
            }}
            className={`organ-popup ${
              activeOrgan?.prostate
                ? `active ${!isMobile && "scale-in-tr"}`
                : `${!isMobile && "active scale-out-tr"}`
            }`}
          >
            <span
              className="d-block d-md-none mobile-close"
              role="button"
              aria-label="Close"
              tabIndex={0}
              onKeyDown={() =>
                setActiveOrgan({
                  head: false,
                  neck: false,
                  chest: false,
                  spleen: false,
                  prostate: false,
                })
              }
              onClick={() =>
                setActiveOrgan({
                  head: false,
                  neck: false,
                  chest: false,
                  spleen: false,
                  prostate: false,
                })
              }
            >
              <img src={iconCloseSvg} alt="mobile close" />
            </span>
            <h4>Pelvic</h4>
            <div className="organ-popup-list">
              <div className="organ-popup-list-header">
                <span>Organs covered</span>
              </div>
              <ul>
                <li>
                  <span>Bladder</span>
                  <CheckSvg />
                </li>
                <li>
                  <span>Ovaries</span>
                  <CheckSvg />
                </li>
                <li>
                  <span>Uterus</span>
                  <CheckSvg />
                </li>
                <li>
                  <span>Prostate</span>
                  <CheckSvg />
                </li>
              </ul>
            </div>
            <a
              href="https://ezra.com/full-body/"
              target="_blank"
              className="link-btn -secondary"
            >
              <span>Read more</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default FullBodyDude;
