import { useEffect, useState } from "react";

export const IsFirefox = () => {
  const [isFirefoxFlag, setIsFirefoxFlag] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    setIsFirefoxFlag(userAgent);
  }, []);

  return isFirefoxFlag;
};
