import React from "react";
import { Row, Col } from "react-bootstrap";
import * as spacerStyles from "./spacer.module.scss";

const Spacer = ({ size, mobileSize }) => {
  const styleObj = {
    ...(typeof size !== "undefined" && {
      "--spacer-bottom-margin": `${size}px`,
    }),
    ...(typeof mobileSize !== "undefined" && {
      "--spacer-bottom-margin-mobile": `${mobileSize}px`,
    }),
  };

  return (
    <Row>
      <Col>
        <div className={spacerStyles.spacer} style={styleObj}></div>
      </Col>
    </Row>
  );
};

export default Spacer;
