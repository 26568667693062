import React, { useState } from "react";
import CountUp from "react-countup";
import FooterStatic from "../footer-static/footer";
import ReactFullpage from "@fullpage/react-fullpage";
import Section3 from "../fullPageSections/section-3/section-3";
import Section4Variant from "../fullPageSections/section-4/section-4-variant";
import TestimonialSlider from "../1000slider/slider";
import { isMobile } from "react-device-detect";
import EzBarChart from "../barChart/barChart";
import EzBarStackedChart from "../barChart/barChartStacked";
import loadable from "@loadable/component";
import { useReportsData } from "../../hooks/reportsData/useReportsData";
import {
  groupedByAgeDummyData,
  staticProcessedData,
} from "../utils/processData";
import FullBodyDude from "../fullbodyDude/fullBodyDude";
import Spacer from "../spacer/spacer";
import CheckMark from "../../assets/svg/checkmark.inline.svg";

const MapBox = loadable(() => import("../map/mapBox"), {
  ssr: false,
});
const pluginWrapper = () => {
  require("../../assets/js/fullpage.parallax.min.js");
};

const FullPageView = () => {
  const anchors = [
    "Welcome",
    "Full Body",
    "Locations",
    "People",
    "Ages",
    "Sex",
    "Mission",
    "Member Stories",
    "Book your scan",
  ];
  const [showScanBarChart, setShowScanBarChart] = useState(false);
  const [showAgesChart, setShowAgesChart] = useState(false);
  const [playSlider, setPlaySlider] = useState(false);
  const reportsData = useReportsData();
  const dataByAge = staticProcessedData();
  const dummyDataByAge = groupedByAgeDummyData();
  const reportsMapData = [];
  reportsData.map((location) =>
    reportsMapData.push({
      type: "Feature",
      properties: {
        id: location.id,
        Amount: location.Amount,
        Home_city: location.Home_city,
        Home_state_country: location.Home_state_country,
        Lat: location.Lat,
        Long: location.Long,
      },
      geometry: {
        type: "Point",
        coordinates: [location.Long, location.Lat],
      },
    })
  );

  return (
    <ReactFullpage
      pluginWrapper={pluginWrapper}
      autoScrolling={false}
      licenseKey={process.env.FULLPAGEJS_KEY}
      scrollingSpeed={1000}
      verticalCentered={false}
      navigation={false}
      navigationPosition={"left"}
      navigationTooltips={anchors}
      normalScrollElements={".scrollable-content"}
      scrollOverflow={false}
      parallax={true}
      parallaxKey="ZXpyYS5jb21fR2h0Y0dGeVlXeHNZWGc9bk96"
      parallaxOptions={{
        type: "reveal",
        percentage: 70,
        property: "translate",
      }}
      responsiveWidth={1000}
      afterLoad={(origin, destination, direction) => {
        if (destination.index === 4) {
          setShowAgesChart(true);
        }
        if (destination.index === 5) {
          setShowScanBarChart(true);
        }
        if (destination.index === 7) {
          setPlaySlider(true);
        }
      }}
      onLeave={(origin, destination, direction) => {
        if (origin.index === 4) {
          setShowAgesChart(false);
        }
        if (origin.index === 5) {
          setShowScanBarChart(false);
        }
        if (origin.index === 7) {
          setPlaySlider(false);
        }
      }}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div
              id="slide1-1"
              data-anchor="Welcome"
              className="section -white-pattern slide-1"
            >
              <div className="fp-bg"></div>
              <div className="container -h-100">
                <div className="content-wrapper">
                  <div className="numbers">
                    <CountUp delay={1} duration={10} end={1000} />
                  </div>
                  <div className="text-wrapper">
                    <h1>Celebrating 1000 Ezra scans</h1>
                    <h4>
                      We’re Ezra, a technology company on a mission to detect
                      cancer early for everyone in the world using MRI &amp;
                      Artificial Intelligence. We’ve now scanned 1,000 people -
                      here’s what we’ve learned.
                    </h4>
                  </div>
                  <div className="arrow-wrapper">
                    <Spacer mobileSize={64} size={64} />
                    <button
                      className="btn btn-black"
                      onClick={() => fullpageApi.moveSectionDown()}
                    >
                      <span>View Report</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="slide1-fullbody"
              className={`section slide-full-body circles-bgr`}
              data-anchor="FullBody"
            >
              <div className="fp-bg -dark-variant"></div>
              <div className="container -fw">
                <div className="text-wrapper-section -white">
                  <h4> Screen for cancer in up to 14 organs.</h4>
                  <h6>
                    The Ezra Full Body Plus screens for cancer across most major
                    vital organs.
                  </h6>
                  <div className="organ-list">
                    <ul className="headline-ul">
                      <li className="headline">
                        <span className="name">Organs Covered</span>
                      </li>
                    </ul>
                    <ul className="oragns-list">
                      <li className="brain">
                        <span className="name">Brain</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Spine</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Thyroid</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Lung</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Liver</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Gallbladder</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Pancreas</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Spleen</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Kidney</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Adrenal Glands</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Bladder</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Ovaries</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Uterus</span>
                        <CheckMark />
                      </li>
                      <li className="brain">
                        <span className="name">Prostate</span>
                        <CheckMark />
                      </li>
                    </ul>
                    <a
                      className="link-btn -secondary -white"
                      href="https://ezra.com/full-body/"
                      target="_blank"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
                <div className="animated-dude">
                  <FullBodyDude />
                </div>
              </div>
            </div>
            <div
              id="slide1-3"
              data-anchor="Locations"
              className="section -white-pattern"
            >
              <div className="fp-bg"></div>
              <Section3 fullpageApi={fullpageApi} />
            </div>

            <div
              id="slide1-2"
              data-anchor="People"
              className="section circles-bgr"
            >
              <div className="fp-bg"></div>
              <div className="container -fw">
                <div className="text-wrapper-section -white">
                  <h4>From New York City to Australia</h4>
                  <h6>
                    Even though we only have a presence in three US cities,
                    we’ve scanned people all over the world.
                  </h6>
                  <p className="airplane-icon">
                    <span>
                      In fact, our very first paying member (signed up in
                      February 2019) lives in Germany and flew to New York to
                      get his Ezra Scan.
                    </span>
                  </p>
                </div>
                <div className="map-wrapper scrollable-content">
                  <MapBox isMobile={isMobile} reportsMapData={reportsMapData} />
                </div>
              </div>
            </div>

            <div
              id="slide1-4"
              data-anchor="PeopleAges"
              className="section -slide-4 -white-pattern"
            >
              <div className="fp-bg -light"></div>
              <div className="container -fw">
                <div className="text-wrapper-section -dark">
                  <h4>A scan designed for everyone</h4>
                  <h6>
                    We’ve scanned people as young as 20, as old as 84, and
                    everything in between.
                  </h6>
                  <p className="graph-icon">
                    <span>The median age of Ezra members is 48.</span>
                  </p>
                </div>
                <div className="bar-wrapper">
                  <EzBarChart
                    isMobile={isMobile}
                    data={showAgesChart ? dataByAge : dummyDataByAge}
                  />
                </div>
              </div>
            </div>

            <div
              id="slide1-4-1"
              data-anchor="PeopleGender"
              className="section -slide-4 circles-bgr"
            >
              <div className="fp-bg -dark"></div>
              <div className="container -fw">
                <div className="text-wrapper-section -white">
                  <h4>For both men and women</h4>
                  <h6>
                    40% of our members are female, 60% are male. We want to take
                    care of everyone.
                  </h6>
                  {/* <p>Over 70% of our members in New York are women.</p> */}
                </div>
                <div className="bar-wrapper">
                  <EzBarStackedChart
                    isMobile={isMobile}
                    data={showScanBarChart ? dataByAge : dummyDataByAge}
                  />
                </div>
              </div>
            </div>

            <div
              id="slide1-6"
              data-anchor="OurMission"
              className="section -slide-6 -dark-pattern"
            >
              <div className="fp-bg -white"></div>
              <Section4Variant />
            </div>

            <div
              id="slide1-8"
              className={`section -dark-pattern fp-auto-height`}
            >
              <div className="fp-bg -dark"></div>
              <TestimonialSlider playSlider={playSlider} />
            </div>

            <div id="slide1-9" className={`section fp-auto-height`}>
              <div className="fp-bg "></div>

              <div className="wrapper -footer">
                <FooterStatic />
              </div>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
};

export default FullPageView;
