import React from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Seo from "../components/seo";
import FullPageView from "../components/reactFullpage/reactFullpage";
import FullPageMobileView from "../components/reactFullpage/reactFullpageMobile";
import "../sass/_members1000.scss";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { IsFirefox } from "../hooks/deviceDetect/useFirefoxDetect";

const IndexPage = () => {
  const breakpoints = useBreakpoint();
  const ffFlag = IsFirefox();
  return (
    <main>
      <Seo
        title="Ezra Full Body Cancer Screening — 1,000 members"
        description="Join our mission to detect cancer early for everyone in the world using MRI &amp; Artificial Intelligence."
      />
      <div className={`rightStickyMenu ${ffFlag ? "ffx" : ""}`}>
        <p>EZRA - THE FIRST 1000 MEMBERS</p>
        <div className="social">
          <TwitterShareButton
            className="btn-head -twitter"
            url="https://1000.ezra.com/"
            title="The @ezrainc team is on a mission to detect cancer for everyone in the world using MRI &amp; Artificial Intelligence. They've now scanned 1000 people - here's what they've learned -"
            via="ezrainc"
          >
            TWEET
          </TwitterShareButton>
          <FacebookShareButton
            className="btn-head -fb"
            url="https://1000.ezra.com/"
            quote="The @Ezra team is on a mission to detect cancer for everyone in the world using MRI &amp; Artificial Intelligence. They've now scanned 1000 people - here's what they've learned - https://1000.ezra.com/"
          >
            SHARE
          </FacebookShareButton>
        </div>
      </div>
      {breakpoints.md ? <FullPageMobileView /> : <FullPageView />}
    </main>
  );
};

export default IndexPage;
