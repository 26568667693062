import React from "react";
import Spacer from "../spacer/spacer";
import { Link } from "gatsby";
import LogoSmallWhite from "../../assets/logo-small-white.svg";
import "./footer.scss";

const FooterStatic = () => {
  return (
    <>
      <section className="homepage-banner-dark">
        <div className="container-fluid -mobile-no-spacing ">
          <Spacer size={128} mobileSize={64} />
          <div className="row">
            <div className="col-14 col-md-10 offset-md-1 col-lg-11 offset-lg-1 col-xl-14 offset-xl-1">
              <div className="text-wrapper">
                <h4>
                  Join our mission to detect cancer early for everyone in the
                  world.
                </h4>
                <a
                  href="https://v2.my.ezra.com/join"
                  className="btn btn-yellow -secondary"
                >
                  <span>Book Your Scan</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="colophon" className="site-footer -variant-b">
        <div className="site-info">
          <div className="container">
            <Spacer size={64} mobileSize={32} />
          </div>

          <div className="container-fluid -no-padding-lr">
            <div className="row -no-margin-lr -border-col">
              <div className="col-16  -with-border-bottom"></div>
            </div>
          </div>

          <div className="container -mobile-no-spacing">
            <div className="row -border-col justify-content-center ">
              <div className="col-16 col-lg-4 -with-border -d-flex -center -with-logo">
                <Link to="/" title="Ezra" rel="home">
                  <img
                    className="footer-logo"
                    src={LogoSmallWhite}
                    alt="Ezra Small Logo"
                  />
                </Link>
              </div>
              <div className="col-7 col-lg-3 -with-border -no-border-bottom-mobile">
                <h5>Contact</h5>
                <ul>
                  <li>
                    <a href="mailto:hello@ezra.com">hello@ezra.com</a>
                  </li>
                  <li>
                    <a href="tel:888 402 3972">888 402 3972</a>
                  </li>
                </ul>
              </div>
              <div className="col-7 col-lg-3 -with-border -no-border-bottom-mobile -with-border-right-mobile">
                <h5>Company</h5>
                <ul id="company-menu" className="menu">
                  <li className=" menu-item ">
                    <a href="https://ezra.com/mission/">Our Mission</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/advisors/">Our Advisors</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/physicians/">
                      Ezra for Physicians
                    </a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/careers/">Careers</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/blog/">Blog</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/press/">Press</a>
                  </li>
                </ul>
              </div>
              <div className="col-7 col-lg-3 -with-border d-none d-lg-block">
                <h5>Resources</h5>
                <ul id="resources-menu" className="menu">
                  <li className=" menu-item ">
                    <a href="https://ezra.com/locations/">Locations</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/pricing/">Pricing</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/prostate-ai/">Prostate AI</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/faq/">FAQ</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/terms-of-use/">
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/privacy/">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-7 col-lg-3 -with-border d-none d-lg-block">
                <h5>Follow Us</h5>
                <ul className="social">
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/ezrainc"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://twitter.com/ezrainc"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/ezrainc/"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.linkedin.com/company/ezrainc/"
                    >
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row -border-col justify-content-center d-flex d-lg-none">
              <div className="col-16  -with-border-bottom "></div>
              <div className="col-7 col-lg-3 -with-border">
                <h5>Resources</h5>
                <ul id="resources-menu" className="menu">
                  <li className=" menu-item ">
                    <a href="https://ezra.com/locations/">Locations</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/pricing/">Pricing</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/prostate-ai/">Prostate AI</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/faq/">FAQ</a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/terms-of-use/">
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li className=" menu-item ">
                    <a href="https://ezra.com/privacy/">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="col-7 col-lg-3 -with-border">
                <h5>Follow Us</h5>
                <ul className="social">
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/ezrainc"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://twitter.com/ezrainc"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/ezrainc/"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.linkedin.com/company/ezrainc/"
                    >
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container-fluid -no-padding-lr">
            <div className="row -no-margin-lr -border-col">
              <div className="col-16  -with-border-bottom"></div>
            </div>
          </div>
          <div className="container">
            <Spacer size={24} mobileSize={16} />
            <div className="row">
              <div className="col-16 text-center">
                <p className="copy">&copy; {new Date().getFullYear()} Ezra</p>
              </div>
            </div>
            <Spacer size={24} mobileSize={16} />
          </div>
        </div>
      </footer>
    </>
  );
};
export default FooterStatic;
