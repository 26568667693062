import "./src/sass/style.scss";
import "./src/sass/preloader.scss";

export const onClientEntry = () => {
  var elem = document.getElementById("firstIntro");
  if (elem) {
    if (
      document.cookie
        .split(";")
        .some((item) => item.includes("splashScreen=false"))
    ) {
      elem.remove();
    } else {
      window.onload = () => {
        setTimeout(() => {
          elem.classList.add("fade");
        }, 5800);
      };

      window.onload = () => {
        setTimeout(() => {
          elem.remove();
          document.cookie = "splashScreen=false";
        }, 7500);
      };
    }
  }
};
