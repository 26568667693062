import React from "react";

import Seo from "../components/seo";

const NotFoundPage = ({ data, location }) => {
  return (
    <main>
      <Seo title="1000 Members" description="1000 Members have joined Ezra" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </main>
  );
};

export default NotFoundPage;
