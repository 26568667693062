import React from "react";

const Section4 = () => {
  return (
    <>
      <div className="container -fw">
        <div className="text-wrapper-section -dark">
          <h4 style={{ margin: 0 }}>Delivering on our mission.</h4>
          {/* <h6>7% of our members have found potential cancer</h6> */}
          {/* <p>The most common cancer found in both sexes was ‘xxx’.</p> */}
        </div>
        <div className="number-wrapper">
          {/* <Illustration /> */}
          <h2>13%</h2>
          <p>of our members have found potential cancer</p>
        </div>
      </div>
    </>
  );
};

export default Section4;
