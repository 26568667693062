const dummyData = [
  // {
  //   name: "0-9",
  //   scans: 0,
  //   minAge: 0,
  //   maxAge: 9,
  // },
  // {
  //   name: "10-19",
  //   scans: 0,
  //   minAge: 10,
  //   maxAge: 19,
  // },
  {
    name: "20-29",
    scans: 0,
    minAge: 20,
    maxAge: 29,
  },
  {
    name: "30-39",
    scans: 0,
    minAge: 30,
    maxAge: 39,
  },
  {
    name: "40-49",
    scans: 0,
    minAge: 40,
    maxAge: 49,
  },
  {
    name: "50-59",
    scans: 0,
    minAge: 50,
    maxAge: 59,
  },
  {
    name: "60-69",
    scans: 0,
    minAge: 60,
    maxAge: 69,
  },
  {
    name: "70-79",
    scans: 0,
    minAge: 70,
    maxAge: 79,
  },
  {
    name: "80-89",
    scans: 0,
    minAge: 80,
    maxAge: 89,
  },
  {
    name: "90-99",
    scans: 0,
    minAge: 90,
    maxAge: 99,
  },
];
const actualData = [
  // {
  //   name: "0-9",
  //   scans: 0,
  //   minAge: 0,
  //   maxAge: 9,
  // },
  // {
  //   name: "10-19",
  //   scans: 0,
  //   minAge: 10,
  //   maxAge: 19,
  // },
  {
    name: "20-29",
    scans: 0,
    minAge: 20,
    maxAge: 29,
    male: 0,
    female: 0,
  },
  {
    name: "30-39",
    scans: 0,
    minAge: 30,
    maxAge: 39,
    male: 0,
    female: 0,
  },
  {
    name: "40-49",
    scans: 0,
    minAge: 40,
    maxAge: 49,
    male: 0,
    female: 0,
  },
  {
    name: "50-59",
    scans: 0,
    minAge: 50,
    maxAge: 59,
    male: 0,
    female: 0,
  },
  {
    name: "60-69",
    scans: 0,
    minAge: 60,
    maxAge: 69,
    male: 0,
    female: 0,
  },
  {
    name: "70-79",
    scans: 0,
    minAge: 70,
    maxAge: 79,
    male: 0,
    female: 0,
  },
  {
    name: "80-89",
    scans: 0,
    minAge: 80,
    maxAge: 89,
    male: 0,
    female: 0,
  },
  {
    name: "90-99",
    scans: 0,
    minAge: 90,
    maxAge: 99,
    male: 0,
    female: 0,
  },
];

const staticData = [
  {
    female: 42,
    male: 48,
    maxAge: 29,
    minAge: 20,
    name: "20-29",
    scans: 90,
  },
  {
    female: 126,
    male: 242,
    maxAge: 39,
    minAge: 30,
    name: "30-39",
    scans: 368,
  },
  {
    female: 164,
    male: 276,
    maxAge: 49,
    minAge: 40,
    name: "40-49",
    scans: 440,
  },
  {
    female: 122,
    male: 296,
    maxAge: 59,
    minAge: 50,
    name: "50-59",
    scans: 418,
  },
  {
    female: 62,
    male: 276,
    maxAge: 69,
    minAge: 60,
    name: "60-69",
    scans: 338,
  },
  {
    female: 10,
    male: 56,
    maxAge: 79,
    minAge: 70,
    name: "70-79",
    scans: 66,
  },
  {
    female: 0,
    male: 4,
    maxAge: 89,
    minAge: 80,
    name: "80-89",
    scans: 4,
  },
  {
    female: 0,
    male: 2,
    maxAge: 99,
    minAge: 90,
    name: "90-99",
    scans: 2,
  },
];

export const groupedByAgeData = (data) => {
  if (!data) {
    return;
  }
  const groupBy = (array, key) => {
    return array.reduce((result, obj) => {
      (result[obj[key]] = result[obj[key]] || []).push(obj);
      return result;
    }, []);
  };

  let groupedByAgeData = groupBy(data, "Age");
  for (const [key, value] of Object.entries(groupedByAgeData)) {
    const rangeItem = actualData.find(
      (item) => key >= item.minAge && key <= item.maxAge
    );
    if (rangeItem) {
      rangeItem.male += value.filter((item) => item.Gender === "M").length;
      rangeItem.female += value.filter((item) => item.Gender === "W").length;
      rangeItem.scans += value.length;
    }
  }
  return actualData;
};

export const staticProcessedData = () => {
  return staticData;
};

export const groupedByAgeDummyData = () => {
  return dummyData;
};
